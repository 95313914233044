import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import AccountIcon from '@mui/icons-material/AccountCircle';
import { AssessmentIcon, BatchIcon, DashboardIcon } from 'components/Icons';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  header: {
    width: '259px',
    display: 'flex',
    zIndex: 1000,
    boxShadow: '2px 0px 4px rgb(0 0 0 / 15%)'
  },
  textDecorationNone: {
    textDecoration: "none",
    textTransform: "none",
    "& >*": {
      textTransform: "none",
      textDecoration: "none"
    }
  },
  link: {
    textTransform: "none",
    textDecoration: "none",
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#000000",
  },
  topItems: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "16px",
    color: "#000000",
    margin: "8px 0px",
    width: "fit-content",
    padding: "5px 0px",
  },
  selected: {
    borderBottom: "2px solid black",
    fontWeight: 600
  },
  sideBar: {
    boxSizing: 'border-box',
    border: 'none',
    minWidth: 242,
},
menuItem: {
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  transition: "200ms ease-in-out",
  cursor: "pointer",
  padding: theme.spacing(5)
},
}));

const menuItems = [
  {
      label: "Dashboard",
      icon: DashboardIcon
  },
  {
      label: "Batches",
      icon: BatchIcon,
  },
  {
      label: "Assessments",
      icon: AssessmentIcon
  },
];;

function MenuItem({ menu }) {
  const classes = useStyles();
  const theme = useTheme();

  const MenuIcon = menu.icon;
  const [selected, setSelected] = React.useState(false);


  return  (
     <>
       <Box to={menu.path} className={classes.link}>
         <Box className={selected ? classes.activeMenuItem : classes.menuItem}>
         <MenuIcon
              color={
                  selected
                      ? theme.palette.shades['clr-white-900']
                      : theme.palette.tertiary['clr-400']
              }
          />
             <Typography
                  variant='h6-semiBold'
                  color={selected ? 'shades.clr-white-900' : 'neutral.clr-600'}
              >
                  {menu.label}
              </Typography>
         </Box>
       </Box>
     </>
  ) ;
}

function InPrepHeaderPreview({ imageSrc, translate, rotation, scale }) {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box
        display="flex"
        flexDirection='column'
        width="100%"
      >
        <div style={{ height: '60px', width: '196px', overflow: 'hidden' , margin : '32px' , display : 'flex', justifyContent : 'center' , boxShadow : '0px 0px 2px 0px #00000040' }}>
          <img
            src={imageSrc}
            alt={"prview-logo"}
            style={{
              height: '60px',
              width: 'auto',
              transform: `translate(${translate.x}px, ${translate.y}px) rotate(${rotation}deg) scale(${scale})`
            }}
          />
        </div>
        <Box className={classes.sideBar}>
          {menuItems.map((menuItem, index) => (
            <MenuItem key={index} menu={menuItem} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default InPrepHeaderPreview;