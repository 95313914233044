import Bowser from "bowser";
import Session from "./Session";
import * as Papa from "papaparse";
import dayjs from "dayjs";

export const subMinutes = function (date, minutes) {
  return new Date(date.getTime() - minutes * 60000);
};

export const getViewportDimensions = () => {
  let width;
  let height;

  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth !== "undefined") {
    width = window.innerWidth;
    height = window.innerHeight;
  }

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (
    typeof document.documentElement !== "undefined" &&
    typeof document.documentElement.clientWidth != "undefined" &&
    document.documentElement.clientWidth !== 0
  ) {
    width = document.documentElement.clientWidth;
    height = document.documentElement.clientHeight;
  }

  // older versions of IE
  else {
    width = document.getElementsByTagName("body")[0].clientWidth;
    height = document.getElementsByTagName("body")[0].clientHeight;
  }
  return { width, height };
};

export const isBrowserSupported = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  const isValidBrowser = browser.satisfies({
    windows: {
      "internet explorer": ">9999",
      safari: ">9999",
      chrome: ">57",
      firefox: ">9999",
      opera: ">9999",
      edge: ">79",
    },
    linux: {
      "internet explorer": ">9999",
      safari: ">9999",
      chrome: ">57",
      firefox: ">9999",
      opera: ">9999",
      edge: ">79",
    },
    macos: {
      "internet explorer": ">9999",
      safari: ">9999",
      chrome: ">57",
      firefox: ">9999",
      opera: ">9999",
      edge: ">79",
    },
    ios: {
      "internet explorer": ">9999",
      safari: ">9999",
      chrome: ">57",
      firefox: ">9999",
      opera: ">9999",
      edge: ">79",
    },
    android: {
      "internet explorer": ">9999",
      safari: ">9999",
      chrome: ">9999",
      firefox: ">9999",
      opera: ">9999",
      edge: ">9999",
    },
    "Chrome OS": {
      "internet explorer": ">9999",
      safari: ">9999",
      chrome: ">57",
      firefox: ">9999",
      opera: ">9999",
      edge: ">79",
    },
  });
  return isValidBrowser;
};

const getUserMedia = (constraints) => {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      var getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(
          new Error("getUserMedia is not implemented in this browser")
        );
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  } else {
    return navigator.mediaDevices.getUserMedia(constraints);
  }
};

export const checkMicrophone = () => {
  return new Promise((resolve, reject) => {
    getUserMedia({ audio: true })
      .then(function (stream) {
        resolve(true);
      })
      .catch(function (err) {
        resolve(false);
      });
  });
};

export const secondsToMMSS = (seconds) => {
  if (seconds < 0) return "";
  var MM = Math.floor(seconds / 60);
  var SS = seconds - MM * 60;

  if (MM < 10) MM = `0${MM}`;
  if (SS < 10) SS = `0${SS}`;

  return `${MM}:${SS}`;
};

export const getClientPrefix = () => {
  const clientName = Session?.client
    ? Session?.client?.name
    : Session.user?.name;
  return clientName
    ? clientName
      .split(" ")
      .map((part) => part.toLowerCase())
      .join("-")
    : clientName;
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const formatSeconds = (time = 0) => {
  const duration = +time;
  if (duration === 0) return "0 secs";

  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  let result = "";
  if (minutes) result += `${minutes} min `;
  result += `${seconds} secs`;

  return result;
};

export const getPercentageChange = (currentScore = 0, previousScore = 0) => {
  if (previousScore === 0) {
    return 100;
  }
  return Math.round(((currentScore - previousScore) / previousScore) * 100);
}

export const getAverageScore = (speechScore = 0, contentScore = 0) => {
  const { communication = 50, content = 50 } =
    Session?.user?.metadata?.performanceWeightage || {};

  return Math.round(
    (speechScore * communication + contentScore * content) / 100
  );
};

export const calculatePrevAverage = (dataArray, i) => {
  if (i === 0) {
    return { communicationOverall: 0, contentOverall: 0 }
  }

  const count = dataArray.length || 1;

  const { communicationOverall, contentOverall } = dataArray.reduce(
    (pre, data) => ({
      communicationOverall: pre.communicationOverall + data.communicationOverall,
      contentOverall: pre.contentOverall + data.contentOverall,
    }),
    { communicationOverall: 0, contentOverall: 0 }
  );

  return {
    contentOverall: contentOverall / count,
    communicationOverall: communicationOverall / count,
  };
};

//function to retrieve nested values from an object
export const getValueFromNestedKeys = (data, fields) => {
  return fields.reduce((obj, key) => (obj && obj[key] !== undefined) ? obj[key] : undefined, data);
};

// sort table content with specific column
export const sortRowsByColumn = (rows, column, sortBy) => {
  const stringCompare = (a, b) => a.localeCompare(b);
  const numericCompare = (a, b) => a - b;

  const compareFunction = (valueA, valueB) => {
    return typeof valueA === 'string'
      ? stringCompare(valueA, valueB) : numericCompare(valueA, valueB);
  };

  const sortedList = rows.slice().sort((a, b) => {
    // Get the values of the specified column in the 'a' and 'b' rows
    const aValue = getValueFromNestedKeys(a, column.field.split('.'));
    const bValue = getValueFromNestedKeys(b, column.field.split('.'));

    if (sortBy === 'asc') {
      return compareFunction(aValue, bValue);
    } else {
      return compareFunction(bValue, aValue);
    }
  });

  return sortedList;
};

export const searchStringInString = (searchQuery, targetString) => {
  const query = searchQuery?.toLowerCase();
  const target = targetString?.toLowerCase();
  return target.includes(query);
}

export const applyFiltersByHistory = (pageFilters, setPageFilters, setInitialFilters) => {
  const pageHistory = Session.pageHistory
  const pathname = window.location.pathname;
  try {
    if ((pageHistory ? (pageHistory.previousPage.split('/')).length >= 2 : false) &&
      pathname === ((pageHistory.previousPage.split('/')).slice(0, 2).join('/')).toString()) {
      if (pageFilters) {
        return pageFilters[pathname] ?
          setInitialFilters(pageFilters[pathname]) : setPageFilters(null);
      }
    }
    else {
      return setPageFilters(null);
    }
  }
  catch (error) {
    return setPageFilters(null);;
  }
}


export const exportToCsv = (columns, forTemplateAnalytics, rows, exportRows, filters, name, individualAttempts = false) => {
  const dataRows = individualAttempts ? exportRows : rows;

  const headers = [];
  const dynamicHeaders = new Set();

  // Find the position of the "name" column
  const nameColumnIndex = columns.findIndex(col => col.field === 'name');

  columns.forEach((col, index) => {
    if (filters.includes(col.field) || col.export) {
      let label = col.label;
      if (label === "Allotted Time For Interview") {
        label = "Allotted Time For Interview (in mins)";
      } else if (label === "Time Taken By Candidate") {
        label = "Time Taken By Candidate (in mins)";
      } else if (label === "Question Wise Analysis") {
        label = " ";
      }
      headers.push(label);

      if (index === nameColumnIndex && individualAttempts) {
        headers.push("Attempt No.", "Start Time Stamp", "Session Finished (Y/N)");
      }
    }
  });

  if (individualAttempts && filters.includes('question_wise_analysis')) {
    dataRows.forEach(row => {
      if (row.analysisPerQuestion && Array.isArray(row.analysisPerQuestion)) {
        row.analysisPerQuestion.forEach((question, index) => {
          Object.keys(question).forEach(key => {
            if (
              (key === 'overall' && filters.includes('overallScore')) ||
              (key === 'content' && filters.includes('contentOverall')) ||
              (key === 'speech' && filters.includes('communicationOverall')) ||
              (key === 'confidence' && filters.includes('communicationConfidence')) ||
              (key === 'allottedTime' && filters.includes('allottedTime')) ||
              (key === 'timeTaken' && filters.includes('timeTaken')) ||
              (key === 'similarity' && filters.includes('contentSimilarity')) ||
              (key === 'fluency' && filters.includes('communicationFluency')) ||
              (key === 'relevance' && filters.includes('contentRelevance')) ||
              (key === 'delivery' && filters.includes('communicationDelivery')) ||
              (key === 'word_choice' && filters.includes('communicationWord_Choice')) ||
              (key === 'logic_flow' && filters.includes('contentLogic_Flow'))
            ) {
              dynamicHeaders.add(`Q${index + 1}_${key}`);
            }
          });
        });
      }
    });

    dynamicHeaders.forEach(header => {
      let label = header.charAt(0).toUpperCase() + header.slice(1);
      if (header.includes("_timeTaken")) {
        label = header.replace("_timeTaken", "_timeTaken (in mins)");
      } else if (header.includes("_allottedTime")) {
        label = header.replace("_allottedTime", "_allottedTime (in mins)");
      }
      headers.push(label);
    });
  }

  const rowData = dataRows.map(row => {
    let data = columns
      .filter(col => filters.includes(col.field) || col.export)
      .reduce((acc, col, index) => {
        let value;
        if (typeof row[col.field] === 'number') {
          value = Math.round(row[col.field]);
        } else if (col.type === 'date') {
          value = dayjs(row[col.field]).toISOString();
        } else {
          value = row[col.field];
        }
        acc.push(value);

        if (index === nameColumnIndex && individualAttempts) {
          acc.push(
            row.attemptNumber || '',
            row.startedAt ? dayjs(row.startedAt).toISOString() : '',
            row.sessionFinished ? 'Y' : 'N'
          );
        }
        return acc;
      }, []);

    if (individualAttempts && filters.includes('question_wise_analysis')) {
      const dynamicData = {};
      dynamicHeaders.forEach(header => {
        dynamicData[header] = " ";
      });

      if (row.analysisPerQuestion && Array.isArray(row.analysisPerQuestion)) {
        row.analysisPerQuestion.forEach((question, index) => {
          Object.keys(question).forEach(key => {
            const dynamicKey = `Q${index + 1}_${key}`;
            if (dynamicHeaders.has(dynamicKey)) {
              dynamicData[dynamicKey] = question[key];
            }
          });
        });
      }

      dynamicHeaders.forEach(header => {
        data.push(dynamicData[header]);
      });
    }

    return data;
  });

  let csvContent = Papa.unparse({ data: rowData, fields: headers }, {});

  csvContent = "data:text/csv;charset=utf-8,".concat(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodeURI(csvContent));
  link.setAttribute(
    "download",
    `${name}-${dayjs().format("DD-MM-YYYY-HH-mm-ss")}.csv`
  );

  link.click();
};

export const round = (number = 0) => {
  const _number = isNaN(number) ? 0 : number;

  return Math.round(_number);
}

export const getMonths = (prevDate, newDate) => {
  prevDate = dayjs(prevDate).startOf('day');
  newDate = dayjs(newDate).startOf('day');
  if (dayjs(prevDate).isBefore(newDate)) {
    const diffInMonths = Math.floor(newDate.diff(prevDate, 'day') / 30);
    return diffInMonths;
  } else {
    return 0;
  }
}

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export * from "./cryptoUtil";
export * from "./date";
export * from "./roles";
export * from "./Session";
