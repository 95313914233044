import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "components/CustomFields";
import makeStyles from "@mui/styles/makeStyles";

import CustomButton, { CustomLoadingButton } from "components/CustomButton";
import { useSnackbar } from "contexts";
import { isValidUrl, Session } from "utils";
import { saveClient } from "services";

const useStyles = makeStyles((theme) => ({
    urlContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: 2
    }
}));

function CreateWebhook() {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [loading, setLoading] = React.useState(false);
    const [urls, setUrls] = React.useState([]);

    React.useEffect(() => {
        if (Session.user.metadata.inprepWebhookUrls) {
            const existUrls = Session.user.metadata.inprepWebhookUrls.map((url, i) => (
                { value: url, id: `url-input-${i}`, existed: true }
            ));
            setUrls(existUrls);
        }
    }, []);

    const handleAdd = React.useCallback(() => {
        if (urls.length >= 5) {
            snackbar.error('You can add only 5 webhooks');
            return;
        }
        setUrls([...urls, { value: '', id: `url-input-${urls.length}` }]);
    }, [urls]);

    const handleUrlChange = React.useCallback((event) => {
        const newUrls = urls.map((url) => (
            (url.id === event.target.id) ?
                { ...url, value: event.target.value } : url
        ));

        setUrls(newUrls);
    }, [urls]);

    const handleUrlDelete = React.useCallback((urlId) => {
        const newUrls = urls.filter((url) => (
            (url.id !== urlId)
        ));

        setUrls(newUrls);
    }, [urls]);

    const saveWebhookUrls = React.useCallback(async () => {
        try {
            //validate
            if (urls.filter((url) => !isValidUrl(url.value)).length > 0) {
                snackbar.error('Invalid URL(s) found');
                return;
            }

            //save
            setLoading(true);
            const { email, role, name } = Session.user;
            const { userLimit, redirectUrl, logoUrl } = Session.user.metadata;
            const newUrls = urls.map((url) => url.value);
            const newClient = {
                email, role: role._id, name,
                metadata: {
                    userLimit, redirectUrl, logoUrl,
                    inprepWebhookUrls: newUrls
                }
            };

            await saveClient(Session.userId, newClient);
            Session.setInprepWebhookUrls(newUrls);

            snackbar.success('Webhook URLs updated successfully');
        } catch (err) {
            console.error(err);
            snackbar.error('Failed to update webhook URLs');
        } finally {
            setLoading(false);
        }
    }, [urls]);

    return (
        <Box marginTop={5}>
            <Box>
                {
                    urls.map((url, i) => ((
                        <Box className={classes.urlContainer} key={i}>

                            <TextField
                                sx={{ width: '50%', minWidth: 380 }}
                                id={url.id}
                                name={url.id}
                                value={url.value}
                                onChange={handleUrlChange}
                            />

                            <CustomButton
                                variant="contained" color="danger" size="medium" width='68px'
                                style={{ borderRadius: '4px', height: 42 }}
                                onClick={() => handleUrlDelete(url.id)}
                            >
                                Delete
                            </CustomButton>
                        </Box>
                    )))
                }
            </Box>

            <CustomButton
                variant="outlined" color="primary" size="medium" width='90px'
                style={{ borderRadius: '4px' }}
                onClick={handleAdd}
            >
                Add
            </CustomButton>

            <CustomLoadingButton
                loading={loading}
                size="medium"
                onClick={saveWebhookUrls}
                variant='contained'
                disabled={false}
                style={{ borderRadius: '4px' }}
            >
                Save
            </CustomLoadingButton>
        </Box>
    );
}

function WebhookSection() {

    return (
        <Box>
            <Typography variant="body01-bold" color="neutral.clr-900">
                You can add multiple webhook URLs here to receive user attempt
                data after submission & get real-time updates
            </Typography>

            <CreateWebhook />
        </Box>
    );
}

export default WebhookSection;