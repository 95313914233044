import React, { useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import { IconButton as CustomIconButton } from "components/CustomButtons";
import makeStyles from "@mui/styles/makeStyles";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ArchiveIcon from '@mui/icons-material/Archive';
import DraftsIcon from '@mui/icons-material/Drafts';
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import CustomButton from "components/CustomButtons";
import DynamicTable from "components/DynamicTable";
import ScreenHeading from "components/ScreenHeading";
import DuplicateTemplateDialog from "dialogs/DuplicateTemplate";
import TemplateDrafts from "lists/TemplateDrafts";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useEditorStore from "editors/TemplateEditor/editorStore";

import {
  archiveTemplateById,
  deleteTemplateById,
  getBatches,
  getDomains,
  getTemplates,
} from "services";
import { TourSteps, useSnackbar, useTour } from "contexts";
import { Session, applyFiltersByHistory, copyToClipboard, formatDate, getClientPrefix } from "utils";
import { BootstrapTooltip } from "components/InfoIconWithTooltip";
import { WhiteBox } from "components/AppShell";
import { useTheme } from "@mui/styles";
import { ActiveTemplateIcon, ArchiveTemplateIcon, DraftTemplateIcon } from "components/Icons";

const Status = {
  live: { color: "#00664A", label: "Live", bgColor: '#DCFCE7' },
  expired: { color: "#C51407", label: "Expired", bgColor: '#FFE6E3' },
  unpublished: { color: "#BF7900", label: "Unpublished", bgColor: '#FFF6E5' },
};

const useStyles = makeStyles(theme=>({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 56px)",
    paddingBottom: theme.spacing(6),
  },
  chip: {
    borderRadius: '16px',
    padding: '0px 18px',
    width: 'fit-content',
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chipText: {
   borderRadius:'50%'
  },
  tabsRoot: {
    width: '100%', height: 'fit-content' ,
    display: 'flex',
    backgroundColor: 'white', 
    gap: theme.spacing(8), 
    paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1),
    alignItems: 'center', justifyContent: 'left'
  },
  tab: {
    display: 'flex' ,
    gap: theme.spacing(1),
    alignItems: 'center', height: 'fit-content'
  },
  active: {
    color: '#02569D'
  },
  inActive: {
    color: theme.palette.neutral['clr-700'],
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeState: {
    width: '100%', border: '1.5px solid', alignSelf: 'center',
    borderRadius: '1px', borderColor: theme.palette.primary.main
  }
}));

const filters = [
  { field: "domain", type: "select", label: "Domain" },
  { field: "batch", type: "select", label: "Batch" },
  { field: "type", type: "select", label: "Type" },
];

function StatusChip({ bgColor, color, label, tooltip }) {
  const classes = useStyles();

  return (
    <Box className={classes.chip} style={{ backgroundColor: bgColor || '#747775' }}>
      <BootstrapTooltip title={tooltip} >
        <Typography className={classes.chipText} color={color || 'black'}>
          {label || 'Label'}
        </Typography>
      </BootstrapTooltip>
    </Box>
  );
}


function AssessmentTabs({ active = 0, handleActiveChange }) {
  const classes = useStyles();

  const items = React.useMemo(() => [
    {
      id: 'active', 
      label: 'Active', 
      icon: (isActive) => <ActiveTemplateIcon color={isActive ? '#02569D' : '#4E5661'} />,
      tagLine: 'Active assessments will be accessible by students and their data will be continuously updated in reports.'
    },
    {
      id: 'archive', 
      label: 'Archives', 
      icon: (isActive) => <ArchiveTemplateIcon color={isActive ? '#02569D' : '#4E5661'} />, 
      tagLine: 'Archived assessment will not be visible to students, however their data will still appear in reports.'
    },
    {
      id: 'draft', 
      label: 'Drafts', 
      icon: (isActive) => <DraftTemplateIcon color={isActive ? '#02569D' : '#4E5661'} />,
      tagLine: "Draft assessments are not accessible by students and their data won't appear in reports until the assessment is published."
    },
  ], []);

  return (
    <Box pl={2}>
      <Box className={classes.tabsRoot}>
        {
          items.map((item, index) => (
            <Box key={item.id}>
              <Box 
                display='flex' flexDirection='column' gap='4px'
                onClick={() => handleActiveChange(index)}
              >
                <Box
                  className={`${classes.tab} ${active === index ? classes.active : classes.inActive}`}
                >
                  {item.icon(active === index)} 
                  <Typography variant="h6-medium">
                    {item.label}
                  </Typography>
                </Box>
                <Box className={classes.activeState}
                  visibility={(active === index ? 'visible' : 'hidden')}
                >
                </Box>
              </Box>
            </Box>
          ))
        }
      </Box>
      <Box my={3}>
        <Typography variant="h6-medium" color='neutral.clr-400'>
          {items[active].tagLine}
        </Typography>
      </Box>
    </Box>
  );
}


function Templates(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { state: tourState } = useTour();
  const theme = useTheme();

  const [searchParams] = useSearchParams();
  const [activeAssessment, setActiveAssessment] = React.useState(0);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [filterOptions, setFilterOptions] = React.useState({
    type: [
      { id: ["practice"], label: "Practice" },
      { id: ["assignment"], label: "Assignment" },
      { id: ["follow_up"], label: "Follow up" },
    ],
  });
  const [duplicate, setDuplicate] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(false);
  const pageFilters = useEditorStore((state) => state.pageFilters)
  const setPageFilters = useEditorStore((state) => state.setPageFilters);
  const pageHistory = Session.pageHistory

  const [initialFilters, setInitialFilters] = React.useState({
    user: Session.userId,
    include: ["domain", "batch", "user"],
    includeTotal: true,
    orderBy: "createdAt",
    order: "desc",
    archive: false,
    type: ["practice", "assignment", "follow_up"]
  });

  

  const columns = React.useMemo(() => ([
    { field: "name", label: "Name", minWidth: 180, maxWidth: 200 },
    {
      field: "status",
      label: "Status",
      align: "center",
      minWidth: 130,
      flex: 0,
      renderCell: ({ row }) => {
        let status = Status.unpublished;
        let tooltip = `Publising on ${formatDate(row.publishOn, 'DD/MM/YYYY')}`;

        if (row.publishOn && dayjs(row.publishOn).isBefore(new Date())) {
          status = Status.live;
          if(dayjs(row.expiresOn).year()===9999){
            tooltip = `Never Expires`;
          }else{
            tooltip = `Expires on ${formatDate(row.expiresOn, 'DD/MM/YYYY')}`;
          }
        }
        if (row.expiresOn && dayjs(row.expiresOn).isBefore(new Date())) {
          status = Status.expired;
          tooltip = `Expired on ${formatDate(row.expiresOn, 'DD/MM/YYYY')}`;
        }

        return (
          <Box width='100%' display='flex' justifyContent='center'>
            <StatusChip
              color={status.color} bgColor={status.bgColor} label={status.label} tooltip={tooltip}
            />
          </Box>
        );
      },
    },
    {
      id: "type",
      label: "Type",
      minWidth: 100,
      align: "center",
    },
    {
      id: "domain",
      label: "Domain",
      minWidth: 150,
      align: "center",
      renderCell: ({ row }) => row?.domain?.name || "",
    },
    {
      id: "batch",
      label: "Batch",
      minWidth: 100, maxWidth: 180,
      align: "center",
      renderCell: ({ row }) => row?.batch?.name || "-",
    },
    {
      id: "metadata.numberOfQuestions",
      label: "Questions",
      type: "number",
      renderCell: ({ row }) => (row.type === 'follow_up') ? 5 : row?.metadata?.numberOfQuestions || 0,
      align: "center",
    },
    {
      id: "attemptedBy",
      label: "Attempted by",
      type: "number",
      renderCell: ({ row }) => row?.attemptedBy?.length || 0,
      align: "center",
    },
    {
      id: "createdAt",
      label: "Created On", type: 'date',
      minWidth: 116,
      align: "center",
      renderCell: ({ row }) => dayjs(row.createdAt).format("DD-MMM-YYYY"),
    },
    {
      id: "link",
      label: "Invite Link", fixed: true,
      minWidth: 120, disableSorting: true,
      align: "center",
      renderCell: ({ row }) =>
        <IconButton size="small" onClick={(event) => {
          event.stopPropagation();
          const link = `${process.env.REACT_APP_INPREP_URL}/${getClientPrefix()}/home?aid=${row._id}`;
          copyToClipboard(link);
          snackbar.success('Copied');
        }} >
          <ContentCopyIcon fontSize="small" color="primary" />
        </IconButton>,
    },
    {
      id: "analytics",
      label: "Analytics", fixed: true,
      minWidth: 120, disableSorting: true,
      align: "center",
      renderCell: ({ row }) =>
        <CustomIconButton onClick={() => navigate(`${row._id}/performance`)}  variant='contained'
          style={{
                    border : `1px solid ${theme.palette.primary.main}`,
                    padding : theme.spacing(1),
                    marginLeft : theme.spacing(9)
                }}
        >
          <img 
            src='https://assets.languify.in/images/PerformanceIcon.svg' 
            alt='performance' 
          />
        </CustomIconButton>,
    },
  ]), []);

  React.useEffect(() => {
    setInitialFilters({ ...initialFilters, archive: activeAssessment === 1 ? true : false })
  }, [activeAssessment])

  React.useEffect(() => {
    applyFiltersByHistory(pageFilters, setPageFilters, setInitialFilters);
  }, [pageHistory])

  React.useEffect(() => {
    getDomains()
      .then(({ interviewDomains }) => {
        setFilterOptions((fO) => ({
          ...fO,
          domain: interviewDomains.map((d) => ({ id: d._id, label: d.name })),
        }));
      })
      .catch(console.error);

    getBatches({ client: Session.userId })
      .then(({ batches }) => {
        setFilterOptions((fO) => ({
          ...fO,
          batch: batches.map((b) => ({ id: b._id, label: b.name })),
        }));
      })
      .catch(console.error);
  }, []);

  const handleSuccess = React.useCallback(() => {
    setDuplicate(false);
    setRefreshData(true);
  }, []);

  const handleDuplicate = React.useCallback((params) => {
    setDuplicate(params.row);
    setRefreshData(false);
  }, []);

  const handleAssessmentChange = React.useCallback((value) => {
    setActiveAssessment(value);
  }, []);

  const createLink = React.useMemo(() => {
    const forBatch = searchParams.get("fb");

    if (forBatch) return "create?batchId=" + forBatch;
    else return "create";
  }, [searchParams]);

  return (
    <Box className={classes.root}>
      <ScreenHeading title="Assessments" id="assessments-tip">
        <Link to={createLink} style={{ textDecoration: "none" }}>
          <CustomButton variant="contained">Create Assessment</CustomButton>
        </Link>
      </ScreenHeading>

      <Box sx={{ width: '100%' }}>
        <WhiteBox>
          <AssessmentTabs active={activeAssessment} handleActiveChange={handleAssessmentChange}/>
          {
            [0, 1].includes(activeAssessment) ?
              <DynamicTable
                showDummyRows={tourState}
                dummyRows={TourSteps[2].data.templates}
                refreshData={refreshData}
                noRowsMessage="No assessment Found!!"
                hideArchiveAction={false}
                menuOptions={
                  activeAssessment === 0
                    ? [
                        {
                          id: 'duplicate',
                          label: 'Duplicate Assessment',
                          onClick: (event, params) => {
                            event.stopPropagation(); 
                            handleDuplicate(params);
                          },
                        },
                        {
                          id: 'edit',
                          label: 'Edit Questions',
                          onClick: (event, params) => {
                            event.stopPropagation(); 
                            navigate(`/assessments/${params.id}?section=2`);
                          },
                        },
                        {
                          id: 'setting',
                          label: 'Publishing Settings',
                          onClick: (event, params) => {
                            event.stopPropagation(); 
                            navigate(`/assessments/${params.id}?section=3`);
                          },
                        },
                        {
                          label: 'Archive Assessment',
                          id: 'archive',
                          onClick: (event, params) => {
                            event.stopPropagation(); 
                            // archiveItem(params.id); 
                          },
                        },
                      ]
                    : [
                        {
                          label: 'UnArchive Assessment',
                          id: 'unarchive',
                          onClick: (event, params) => {
                            event.stopPropagation(); 
                            // unarchiveItem(params.id); 
                          },
                        },
                      ]
                }
                entity="InterviewTemplate"
                columns={columns}
                filters={filters}
                filterOptions={filterOptions}
                useListProps={{
                  getItems: getTemplates,
                  deleteItemById: deleteTemplateById,
                  archiveItemById: archiveTemplateById,
                  itemName: "interviewTemplates",
                  initialFilters: initialFilters
                }}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                onRowClick={(rowData) => navigate(`${rowData?._id}/performance`)}
              /> :
              <TemplateDrafts />
          }

        </WhiteBox>
      </Box>

      <DuplicateTemplateDialog
        open={!!duplicate}
        onClose={() => setDuplicate(false)}
        template={duplicate}
        onSuccess={handleSuccess}
      />
    </Box>
  );
}

export default Templates;
