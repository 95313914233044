import LogRocket from 'logrocket';

class _Session {
  userId = "";
  user = ""
  accessToken = "";
  accessTokenExpiry = Date.now();
  refreshToken = "";
  refreshTokenExpiry = Date.now();
  roles = [];
  permissions = {};
  pageHistory = {};

  _setUser(user) {
    this.userId = user._id;
    this.user = user;
    this.role = user?.role || {};
    this.permissions = user?.role?.permissions;

    if (process.env.NODE_ENV) {
      LogRocket.identify(this.userId, {
        name: this.user.name, email: this.user.email,
      });
    }
  }

  _unsetUser() {
    this.userId = "";
    this.user = null;
    this.role = {};
    this.permissions = {};
  }

  getUser() {
    return this.user || {};
  }

  getRole() {
    return this.role || {};
  }

  getInprepLogoAndTransform() {
    return this.user.metadata;
  }

  getClientMetadata() {
    return this.client?.metadata || this.user?.metadata || {};
  }

  setInprepLogoAndTransform(logo, transform) {
    this.user.metadata.logoUrl = logo;
    this.user.metadata.inprepLogoTransform = transform;
  }
  setInprepWebhookUrls(urls) {
    this.user.metadata.inprepWebhookUrls = urls;
  }

  onCreateSession({ user, tokens }) {
    this._setUser(user);
    this.accessToken = tokens.accessToken;
    this.accessTokenExpiry = new Date(tokens.accessExpireAt).getTime();
    this.refreshToken = tokens.refreshToken;
    this.refreshTokenExpiry = new Date(tokens.refreshExpireAt).getTime();
    localStorage.setItem("languifyIsLoggedIn", true.toString());
    localStorage.setItem("languifyRefreshTokenExpiry", this.refreshTokenExpiry.toString());
    localStorage.setItem("languifyRefreshToken", this.refreshToken);
  }

  onRestoreSession({ accessToken, accessExpireAt, user }) {
    this._setUser(user);
    this.accessToken = accessToken;
    this.accessTokenExpiry = new Date(accessExpireAt).getTime();
    localStorage.setItem("languifyIsLoggedIn", true.toString());
  }

  clearSession() {
    try {
      this.accessToken = "";
      this.accessTokenExpiry = Date.now();
      this.refreshToken = "";
      this.refreshTokenExpiry = Date.now();
      localStorage.removeItem("languifyIsLoggedIn", false.toString());
      localStorage.removeItem("languifyRefreshTokenExpiry");
      localStorage.removeItem("languifyRefreshToken");
      this._unsetUser();
    } catch (error) {
      console.log(error);
    }
  }

  isLoggedIn() {
    return JSON.parse(localStorage.getItem("languifyIsLoggedIn"));
  }

  checkDeactivatedStatus() {
    if (this?.user && this.isLoggedIn()) {
      if (this.user.deactivated) {
        console.error("DEBUG::checkDeactivatedStatus: USER DEACTIVATED");
        return true;
      } else {
        return false;
      }
    }
  }

  setPageHistory(page) {
    const previousPage = this.pageHistory?.currentPage ? this.pageHistory.currentPage : "";
    const currentPage = page;
    this.pageHistory = { previousPage: previousPage, currentPage: currentPage };
  }
}

export const Session = new _Session();

export default Session;