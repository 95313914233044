import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import ArrowBack from '@mui/icons-material/ArrowBack';
import DetailsForm from "./DetailsForm";
import { getDomains, getPersonalizedInterview } from "services";
import { getJobRoles } from 'services/tagService';
import { useServices } from 'contexts/SingleSessionProvider';
import { copyToClipboard, Session } from "utils";
import { Grow, IconButton, Skeleton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from "contexts";
import { AutoCompleteSelect } from "components/CustomSelectFields";
import CustomButton from "components/CustomButtons";
import TextField, { TextArea } from "components/CustomFields";

const predefinedCompanyState = {
    company: "",
    jobRole: "",
    companyId: "",
    jobId: "",
    domain: "",
    jobDescription: ""
}

const userdefinedCompanystate = {
    company: "",
    jobRole: "",
    domain: "",
    domainId: "",
    jobDescription: ""
}

const useStyle = makeStyles(theme => ({
    container: {
        borderRadius: theme.spacing(1),
        overflow: "hidden",
        background: "linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)",
        boxShadow: " 0px 0px 4px 0px #00000026",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(15, 0)
    },
    box: {
        padding: theme.spacing(10, 15),
        display: "flex",
        gap: theme.spacing(2.5),
        flexDirection: "column",
        width: "700px",
        background: "#FFFFFF",
        borderRadius: theme.spacing(1),
        height: '376px',
    },
    detailFormBox: {
        padding: theme.spacing(5, 7.5),
        display: "flex",
        gap: theme.spacing(2.5),
        flexDirection: "column",
        width: "700px",
        background: "#FFFFFF",
        borderRadius: theme.spacing(0.5),
    },
    boxMock: {
        padding: theme.spacing(5, 7.5),
        display: "flex",
        gap: theme.spacing(2.5),
        flexDirection: "column",
        width: "940px",
        background: "#FFFFFF",
        borderRadius: theme.spacing(1),
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.neutral['clr-400']}`,
        paddingBottom: theme.spacing(3)
    },
    body: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20.48px",
        textAlign: "left"
    },
    question: {
        display: "flex",
        gap: theme.spacing(5)
    },
    dropdown: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1)
    },
    link: {
        cursor: "pointer",
        textAlign: "center",
        marginTop: '10px'
    },
    backBox: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        marginTop: '18px'
    },
    hoverButton: {
        '&:hover': {
            cursor: "pointer",
            boxShadow: "0px 0px 4px 0px #00000026",
            borderRadius: 100
        },
    },
    none: {
        display: "none"
    },
    newCompanyBox: {
        display: "flex",
        gap: theme.spacing(4),
        marginBottom: theme.spacing(3),
        width: '100%'
    },
    newComapnyContainer: {
        display: "flex",
        gap: theme.spacing(2.5),
        flexDirection: "column",
    },
    headBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    creditsTag: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),
        borderRadius: '4px'
    },
    creditText: {
        fontSize: 16,
        fontWeight: 600,
        textDecoration: 'underline'
    },
    creditBox: {
        display: "flex",
        flexDirection: "column",
        width: "700px",
        background: "#FFFFFF",
        borderRadius: theme.spacing(1),
    },
    creditUpperBox: {
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        backgroundColor: theme.palette.danger['clr-100'],
        borderRadius: theme.spacing(1),
    },
    contentBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2.5, 5),
        gap: theme.spacing(2.5)
    },
    totalAttemptText: {
        fontSize: 16,
        fontWeight: 500,
        textAlign: 'center'
    },
    getInTouch: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1.5),
        borderTop: '1px solid #CCD4DE',
        width: '580px',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    touchTextBox: {
        fontSize: 19,
        fontWeight: 700
    },
    copyClipboardBox: {
        display: 'flex',
        gap: theme.spacing(1)
    },
    emailClip: {
        padding: theme.spacing(1, 3),
        display: 'flex',
        gap: theme.spacing(0.5),
        border: '1px solid #CCD4DE',
        borderRadius: '4px'
    },
}))

const ZeroCreditModal = ({ totalAttempts }) => {
    const classes = useStyle();
    const snackbar = useSnackbar();

    return (
        <Grow in={true} timeout={600}>
            <Box className={classes.creditBox}>
                <Box className={classes.creditUpperBox}>
                    <img width={80} height={80} src="https://assets.languify.in/images/Face-in-clouds.gif" alt="cloud" />
                    <Typography variant="h4-regular" color='danger.clr-700'>
                        Your free trial credits are exhausted!
                    </Typography>
                </Box>
                <Box className={classes.contentBox}>
                    <Box textAlign='center' display='flex' flexDirection='column' gap={2}>
                        <Typography variant="body01-bold" color='success.clr-700'>
                            Turn Interview Anxiety into Interview Success!
                        </Typography>
                        <Typography variant="body01-semiBold" color='neutral.clr-800'>
                            Enable your students to walk into any interview with confidence. <br />Secure their preparation; purchase today
                        </Typography>
                    </Box>
                    <Box className={classes.getInTouch}>
                        <Typography variant="body01-bold">Get in touch with us for the credits</Typography>
                        <Box className={classes.copyClipboardBox}>
                            <Box className={classes.emailClip}>
                                <Typography variant="body01-bold" color='primary.clr-500'>growth@languify.in</Typography>
                                <IconButton sx={{ fontSize: '20px', color: '#1961BF', padding: 0, ml: 1 }} onClick={() => {
                                    copyToClipboard('growth@languify.in');
                                    snackbar.success('Copied!');
                                }}>
                                    <ContentCopyIcon fontSize='small' />
                                </IconButton>
                            </Box>
                            <Box className={classes.emailClip}>
                                <Typography variant="body01-bold" color='primary.clr-500'>9969854525</Typography>
                                <IconButton sx={{ padding: 0, color: '#1961BF', fontSize: '20px', ml: 1 }} onClick={() => {
                                    copyToClipboard('9969854525');
                                    snackbar.success('Copied!');
                                }}>
                                    <ContentCopyIcon fontSize='small' />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grow>
    );
}

function NewCompnayData({ newCompanyData, setNewCompanyData, ...props }) {
    const classes = useStyle();
    const [domain, setDomains] = React.useState([])

    React.useEffect(() => {
        (async () => {
            try {
                const { interviewDomains } = await getDomains();
                setDomains(interviewDomains.sort((a, b) => a.name.localeCompare(b.name)));
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const handleDescChange = (e) => {
        setNewCompanyData({ ...newCompanyData, jobDescription: e.target.value })
    }

    const handleRoleChange = (e) => {
        setNewCompanyData({ ...newCompanyData, jobRole: e.target.value })
    }

    const handleNameChange = (e) => {
        setNewCompanyData({ ...newCompanyData, company: e.target.value })
    }

    const handleDomainChange = (e) => {

        const selectedDomain = domain.find(tmp => tmp._id === e.target.value);
        setNewCompanyData(prevState => ({
            ...prevState,
            domainId: e.target.value,
            domain: selectedDomain?._id
        }));
    };

    return (
        <Box {...props} p={2}>
            <Box className={classes.newCompanyBox}>
                <TextField
                    name="role"
                    label="Enter job role"
                    placeholder="Job role "
                    value={newCompanyData.job}
                    onChange={handleRoleChange}
                    width='276px'
                />
                <TextField
                    name="name"
                    label="Enter company name"
                    placeholder="Company name"
                    value={newCompanyData.company}
                    onChange={handleNameChange}
                    background='#FFFFFF'
                    border='1px solid #636F7F'
                    width='276px'
                />
                <AutoCompleteSelect
                    options={domain}
                    label="Select domain"
                    value={newCompanyData.domainId}
                    onChange={handleDomainChange}
                    width='276px'
                />
            </Box>
            <TextArea
                label="Enter Job Description"
                name="jobDescription"
                value={newCompanyData.jobDescription}
                onChange={handleDescChange}
                placeholder="Paste company job description here..."
                rows={6}
            />
        </Box>
    );
}

export default function CreateInterview({ refresh, setRefresh }) {
    const classes = useStyle();
    const service = useServices();

    const [jobRoles , setJobRoles] = useState();
    const [predefinedCompanyData, setPredefinedCompanyData] = useState({ ...predefinedCompanyState })
    const [userdefinedCompanyData, setUserDefinedCompanyData] = useState({ ...userdefinedCompanystate })
    const [selectedJobRoleIndex, setSelectedJobRoleIndex] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [newCompany, setNewCompany] = useState(false);
    const [nextStep, setnextStep] = useState(0);
    const [sendValue, setSendValue] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openCreditModal, setOpenCreditModal] = useState(false)
    const [credits, setCredits] = useState(process.env.REACT_APP_PERSONALIZED_INTERVIEW_LIMIT);
    const [totalTemplates, setTotaltemplates] = useState(0)
    const [totalAttempts, setTotalAttempts] = useState(0)

    const [initialFilters, setInitialFilters] = React.useState({
        user: Session.userId,
        includeTotal: true,
        type: "personalized",
        orderBy: "updatedAt",
        order: "desc",
    });

    React.useEffect(() => {
        const fetchTemplates = async () => {
            try {
                setLoading(true)
                const { total, templates } =
                    await service.callService(getPersonalizedInterview, initialFilters);

                setTotaltemplates(total);
                const totalAttemptsSum = templates.reduce(
                    (acc, template) => acc + (template.totalAttemptsCount || 0)
                    , 0);

                setTotalAttempts(totalAttemptsSum);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };
        fetchTemplates()
    }, [refresh])

    React.useEffect(() => {
        const personalizedInterviewLimit = parseInt(Session?.user?.metadata?.personalizedInterviewLimit?.client
            || process.env.REACT_APP_PERSONALIZED_INTERVIEW_LIMIT)

        if (personalizedInterviewLimit) {
            setCredits(personalizedInterviewLimit - totalTemplates);
        }

    }, [totalTemplates, Session?.user]);

    React.useEffect(() => {
        if (credits <= 0) {
            setTimeout(() => setOpenCreditModal(true), 600);
        }
    }, [credits]);

    React.useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const fetchedJobRoles = await service.callService(getJobRoles);
                setJobRoles(Object.values(fetchedJobRoles).sort((a, b) => a.name.localeCompare(b.name)))
            } catch (err) {
                setError(err.message);
            }
        };
        fetchCompanies();
    }, []);

    React.useEffect(() => {
        const isDisabled = newCompany
            ? !(userdefinedCompanyData.company && userdefinedCompanyData.jobRole &&
                userdefinedCompanyData.domain && userdefinedCompanyData.jobDescription)
            : !(predefinedCompanyData.jobRole && predefinedCompanyData.company);

        const isCreditInvalid = credits <= 0

        setIsButtonDisabled(isDisabled || isCreditInvalid);
    }, [newCompany, predefinedCompanyData, userdefinedCompanyData, credits]);


    const handleJobroleChange = (e) => {
        const selectedIndex = jobRoles.findIndex(jobRole => jobRole._id === e.target.value);
        setSelectedJobRoleIndex(selectedIndex);

        setPredefinedCompanyData({
            ...predefinedCompanyData,
            jobRole: jobRoles[selectedIndex]?.name,
            jobId: e.target.value
        });
    };

    const handleCompanyChange = (e) => {
        const selectedJob = jobRoles[selectedJobRoleIndex].companies
            .find(company => company._id === e.target.value);
        const selectedDomain = jobRoles[selectedJobRoleIndex].domain._id

        setPredefinedCompanyData(prevState => ({
            ...prevState,
            companyId: e.target.value,
            company: selectedJob?.name,
            domain: selectedDomain,
            jobDescription: selectedJob?.jobDescriptions?.content
        }));
    };

    const handleNextStep = () => {
        setSendValue(newCompany ? userdefinedCompanyData : predefinedCompanyData);
        setnextStep(nextStep + 1);
    };

    const handleSetSendValue = () => {
        setPredefinedCompanyData({ ...predefinedCompanyState })
        setUserDefinedCompanyData({ ...userdefinedCompanystate })
        setNewCompany(false)
        setSelectedJobRoleIndex(null)
    }

    const handleCompany = () => { setNewCompany(!newCompany) }

    const handleback = () => { setnextStep(nextStep - 1) }

    return (
        <Box className={classes.container}>
            {openCreditModal
                ? (
                    <ZeroCreditModal totalAttempts={totalAttempts} />
                )
                : (
                    <>
                        <Box className={newCompany ? classes.boxMock : nextStep !== 1 ? classes.box : classes.detailFormBox}>
                            <Box className={classes.headBox}>
                                <Typography variant="h4-medium">
                                    Create mock interview
                                </Typography>
                                {loading ? (
                                    <Box className={classes.creditsTag}>
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: "1rem",
                                                backgroundColor: "#E0E0E0",
                                            }}
                                            width={200}
                                            height={30}
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        className={classes.creditsTag}
                                        sx={{
                                            backgroundColor: credits <= 0 ? "#FFE6E3" : "#F0F5FA",
                                        }}
                                    >
                                        <Typography
                                            className={classes.creditText}
                                            sx={{
                                                color: credits <= 0 ? "#C51407" : "#1961BF",
                                            }}
                                        >
                                            Free trial credits: {credits <= 0 ? 0 : credits}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box className={`${classes.borderBottom} ${classes.backBox}`} >
                                <ArrowBack
                                    onClick={handleback}
                                    className={`${classes.hoverButton} ${!nextStep ? classes.none : ''}`}
                                    sx={{ color: "#1961BF" }}
                                />
                                <Typography variant='h6-medium'>
                                    {nextStep ? "Publishing details & proctorship" : "Job details"}
                                </Typography>
                            </Box>
                            {!nextStep ? (
                                !newCompany ? (
                                    <Box className={classes.question}>
                                        <Box className={classes.dropdown}>
                                        <AutoCompleteSelect
                                                options={ jobRoles || [] }
                                                value={predefinedCompanyData.jobId}
                                                onChange={handleJobroleChange}
                                                label='Select job role'
                                                optionsWidth='280px'
                                                width="280px"
                                                name={predefinedCompanyData.jobRole}
                                            />
                                        </Box>
                                        <Box className={classes.dropdown}>
                                        <AutoCompleteSelect
                                                options={selectedJobRoleIndex !== null
                                                    ? jobRoles[selectedJobRoleIndex].companies.sort((a, b) => a.name.localeCompare(b.name))
                                                    : []}
                                                value={predefinedCompanyData.companyId}
                                                onChange={handleCompanyChange}
                                                label='Select company'
                                                optionsWidth='280px'
                                                width="280px"
                                                name={predefinedCompanyData.company}
                                            />
                                           
                                        </Box>
                                    </Box>
                                ) : (
                                    <NewCompnayData
                                        newCompanyData={userdefinedCompanyData}
                                        setNewCompanyData={setUserDefinedCompanyData}
                                    />
                                )
                            ) : (
                                <DetailsForm setSendValue={handleSetSendValue}
                                    setnextStep={setnextStep} sendValue={sendValue} setRefresh={setRefresh}
                                    nextStep={nextStep}
                                />
                            )}

                            {!nextStep && (
                                <>
                                    <CustomButton
                                        disabled={isButtonDisabled}
                                        variant="contained"
                                        onClick={handleNextStep}
                                        size="medium"
                                        sx={{ marginTop: '10px' }}
                                    >
                                        Next
                                    </CustomButton>
                                    <Typography
                                        className={classes.link} variant="body01-link" color='primary.clr-300'
                                        onClick={handleCompany}
                                    >
                                        {newCompany ?
                                            "Want to select from existing company & job role? Click here"
                                            :
                                            "Didn’t find the company or job role you are looking for? Click here"
                                        }
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </>
                )}
        </Box>
    )
}


