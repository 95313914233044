import Client from "./_client";

export const getJobRoles = async () => {
  let url = "tag/companyJobRoleMap";


  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get job roles. Please try again."
    );
  }
  return result?.data;
}
