import React from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/East';

import CustomButton from 'components/CustomButtons';
import FieldWrapper from './FieldWrapper';
import OptionChips from './OptionChips';
import { getDomains } from 'services';
import { DifficultyLevels } from './constants';
import { useDraft } from 'hooks';
import useEditorStore from './editorStore';
import TextField from 'components/TextField';
import { generateAssessment } from 'services/contentAPI';
import { useLoading, useSnackbar } from 'contexts';
import TemplateTags from './TemplateTags';
import AutoComplete from 'components/Autocomplete';
import { parseCompletion } from 'utils/openaiUtil';

export function AddFieldButton({ children, ...props }) {
    return (
        <CustomButton
            variant="outlined"
            startIcon={<AddIcon />}
            style={{ borderRadius: 24, marginTop: 8 }}
            {...props}
        >
            {children}
        </CustomButton>
    );
}

function TagsFormField({
    label, domain, name, value = [], onChange, title, description, readOnly, ...props
}) {
    const handleClose = (newTags) => onChange(name, newTags);

    return (
        <FieldWrapper
            title={title}
            description={description}
            GridItemProps={{ maxWidth: "100%", xs: 12 }}
            {...props}
        >
            <TemplateTags
                disabled={readOnly}
                tags={value}
                setTags={handleClose}
                domain={domain}
                type={name}
            />
        </FieldWrapper>
    );

}

function DomainField({
    value = "", onChange, error, helperText, options = [], readOnly
}) {
    const handleChange = (e, option) => onChange({
        target: { name: "domain", value: option.id }
    });

    return (
        <FieldWrapper
            title="Assessment domain*"
            description="Domain of the assessment Eg - Product management, Design"
            error={error}
            helperText={helperText}
        >
            <AutoComplete
                readOnly={readOnly}
                options={options.map(o => ({ id: o._id, option: o.name }))}
                value={value}
                onChange={handleChange}
                defaultValue={value}
                size={300}
            />
        </FieldWrapper>
    );
}

const ValidationSchema = Yup.object({
    domain: Yup.string().trim().required("Select a domain"),
    difficulty: Yup.string().trim().required("Select difficulty level"),
    subTopics: Yup.array(Yup.string().trim().required())
        .max(25, "Maximum 25 topics are allowed!")
        .default([]),
    technicalCount: Yup.number()
        .min(0, "Must be greater than or equal to 0")
        .optional()
        .default(1),
    situationalCount: Yup.number()
        .min(0, "Must be greater than or equal to 0")
        .optional()
        .default(1),
    autoGenerate: Yup.boolean().optional().default(false),
});

const DefaultValues = {
    domain: '',
    difficulty: DifficultyLevels[1].value,
    subTopics: [],
    technicalCount: 1,
    situationalCount: 1,
    autoGenerate: false,
};

function FiltersForm({ show }) {
    const loading = useLoading();
    const snackbar = useSnackbar();
    const [domains, setDomains] = React.useState([]);

    const docId = useEditorStore(state => state.templateId);
    const draftId = useEditorStore(state => state.draftId);
    const setDraftId = useEditorStore(state => state.setDraftId);
    const nextSection = useEditorStore(state => state.nextStep);
    const setFilters = useEditorStore(state => state.setFilters);
    const autoGenerate = useEditorStore(state => state.autoGenerate);
    const setAutoGenerate = useEditorStore(state => state.setAutoGenerate);
    const setQuestions = useEditorStore(state => state.setQuestions);
    const setDomain = useEditorStore(state => state.setDomain);
    const setDifficulty = useEditorStore(state => state.setDifficulty);
    const savedDraft = useEditorStore(state => state.savedDraft);

    const {
        load,
        draftSaver,
        data
    } = useDraft({
        onNewDraft: setDraftId,
        docId,
        draftId,
        name: "TEMPLATE_FILTERS",
        fields: new Set([
            "subTopics",
            "domain",
            "difficulty",
            "technicalCount",
            "situationalCount",
            "autoGenerate",
        ]),
    });

    const {
        handleSubmit,
        values,
        handleChange,
        setValues,
        setFieldValue,
        errors,
        handleBlur,
        touched,
    } = useFormik({
        initialValues: DefaultValues,
        validationSchema: ValidationSchema,
        onSubmit: async (values) => {
            setFilters(values);

            if (values.technicalCount + values.situationalCount > 15) {
                snackbar.error('Maximum number of questions allowed is 15. Please adjust the number of questions.');
                return;
            }

            await draftSaver(values)();
            savedDraft();

            const domainName = domains.find(x => x._id === values.domain)?.name || '';
            setDomain(domainName);
            setDifficulty(values.difficulty);

            if (values.autoGenerate && (values.technicalCount + values.situationalCount) > 0) {
                loading.show();
                try {

                    const questions = [];

                    if (values.technicalCount) {
                        const [text] = await generateAssessment({
                            domain: domainName,
                            difficulty: values.difficulty,
                            objectiveCount: values.technicalCount,
                            keywords: values.keywords || [],
                            subTopics: values.subTopics || [],
                            temperature: 0.8
                        });

                        const technicalQuestions = parseCompletion(text);
                        for (let i = 0; i < technicalQuestions.length; i++) {
                            if (i >= values.technicalCount) continue;

                            questions.push({
                                question: technicalQuestions[i].question,
                                expectedAnswer: technicalQuestions[i].idealAnswer || '',
                                type: "technical",
                            });
                        }
                    }

                    if (values.situationalCount) {
                        const [text] = await generateAssessment({
                            domain: domainName,
                            difficulty: values.difficulty,
                            subjectiveCount: values.situationalCount,
                            keywords: values.keywords || [],
                            subTopics: values.subTopics || [],
                            temperature: 0.55
                        });

                        const situationalQuestions = parseCompletion(text);
                        for (let i = 0; i < situationalQuestions.length; i++) {
                            if (i >= values.situationalCount) continue;

                            questions.push({
                                question: situationalQuestions[i].question,
                                expectedAnswer: situationalQuestions[i].idealAnswer || '',
                                type: "situational",
                            });
                        }
                    }

                    setQuestions(questions);

                    nextSection();
                } catch (error) {
                    console.error(error);
                    snackbar.error("Uh Oh, Unable to generate assessment. Please try again!");
                } finally {
                    loading.hide();
                }

            } else {
                nextSection();
            }

        },
    });

    const handleDomainChange = (e) => {
      handleChange(e);
      setFieldValue('subTopics', []);
    }

    const handleDifficultyChange = (e) => {
        handleChange(e);
    }

    React.useEffect(
        () => setFieldValue('autoGenerate', autoGenerate), [autoGenerate]
    );

    React.useEffect(() => load(draftId, docId), [draftId, docId]);

    React.useEffect(() => {
        if (data) {
            setFilters(data);
            setValues(data);
            setAutoGenerate(data?.autoGenerate || false);
            setDifficulty(data?.difficulty);

            const domainName = domains.find(x => x._id === values.domain)?.name || '';
            setDomain(domainName);
        }
    }, [data, setValues]);


    React.useEffect(() => {
        values.autoGenerate = autoGenerate;

        draftSaver(values);
    }, [values, autoGenerate]);

    React.useEffect(() => {
        (async () => {
            try {
                const { interviewDomains } = await getDomains();

                setDomains(interviewDomains);
            } catch (error) { }
        })();
    }, []);

    return (
        <Box pl={2} style={{ height: show ? "100%" : 0 }} overflow="hidden">
            <Typography
                variant="h4-medium"
                pt={2} pl={2} pb={2}
            >
                Details
            </Typography>
            <div>
                <Grid container pt={1}>
                    <DomainField
                        readOnly={docId}
                        options={domains}
                        value={values?.domain}
                        onChange={handleDomainChange}
                        error={touched.domain && !!errors.domain}
                        helperText={touched.domain && errors.domain}
                    />
                    <FieldWrapper
                        title="Difficulty*"
                        description="Select the difficulty level of the questions"
                    >
                        <OptionChips
                            disabled={docId}
                            name="difficulty"
                            onChange={handleDifficultyChange}
                            options={DifficultyLevels}
                            defaultValue={values?.difficulty}
                        />
                    </FieldWrapper>
                    <Box sx={{marginTop : '20px' , marginBottom : '20px' , width : '100%'}}>
                        <TagsFormField
                            readOnly={docId}
                            label={"Add Sub Topic"}
                            title={"Sub Topics"}
                            description={values.autoGenerate ? 'Add at least one topic to make your assessment more precise and targeted' : 'You may add topics here on which the assessment questions will be based.'}
                            name="subTopics"
                            domain={values.domain}
                            value={values?.subTopics}
                            onChange={setFieldValue}
                            error={touched.subTopics && !!errors.subTopics}
                            helperText={touched.subTopics && errors.subTopics}
                        />
                    </Box>

                    {values.autoGenerate && (
                        <>
                            <FieldWrapper
                                title="Technical Questions*"
                                description="Enter the number of questions that test the user's knowledge of specific technical skills or concepts."
                            >
                                <TextField
                                    name="technicalCount"
                                    type="number"
                                    style={{ minWidth: 80, maxWidth: 300, display: 'block' }}
                                    value={values?.technicalCount}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.technicalCount && errors.technicalCount}
                                    helperText={touched.technicalCount && errors.technicalCount}
                                />
                            </FieldWrapper>
                            <FieldWrapper
                                title="Situational Questions*"
                                description="Enter the number of questions that present hypothetical scenarios and ask for the user's opinion or approach."
                            >
                                <TextField
                                    name="situationalCount"
                                    type="number"
                                    style={{ minWidth: 80, maxWidth: 300, display: 'block' }}
                                    value={values?.situationalCount}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.situationalCount && errors.situationalCount}
                                    helperText={touched.situationalCount && errors.situationalCount}
                                />
                            </FieldWrapper>
                        </>
                    )}
                </Grid>
                <Box px={2} mt={4} ml="auto" width="fit-content">
                    <CustomButton
                        onClick={handleSubmit}
                        disabled={docId}
                        endIcon={<ArrowRightIcon />}
                    >
                        {
                            values.autoGenerate ?
                                "Save & Proceed to generate assessment" :
                                "Proceed"
                        }
                    </CustomButton>
                </Box>
            </div>
        </Box>
    );
}

export default FiltersForm;