import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import ScreenHeading from 'components/ScreenHeading';
import General from 'components/Settings/General';
import Scoring from 'components/Settings/Scoring';
import Session from "utils/Session";
import Usage from 'components/Settings/Usage';
import Profile from 'components/Settings/Profile';
import makeStyles from '@mui/styles/makeStyles';
import WebhookSection from 'components/Settings/Webhooks';

import { WhiteBox } from 'components/AppShell';

const useStyle = makeStyles(theme => ({
  tab: {
    ...theme.typography['body01-bold'],
    textTransform: 'capitalize',
    height: 20,
  },
  tabPanel: {
    paddingLeft: 14,
    paddingTop: 0,
  }
}));

function SettingTitle({ title = 'Title' }) {

  return (
    <Box mt={5} mb={3}>
      <Typography variant='h4-medium'>
        {title}
      </Typography>
    </Box>
  )
}

function Settings(props) {
  const user = Session.getUser();
  const classes = useStyle();

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box display="flex" flexDirection="column" minHeight='calc(100vh - 56px)' pb={6}>
      <ScreenHeading title="Settings" id="settings-tip">
        <Box>
          <Typography variant='h6-medium'>{user.name}</Typography><br />
          <Typography variant="body01-bold" color='neutral.clr-600'>{user.email}</Typography>
        </Box>
      </ScreenHeading>
      <WhiteBox >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange} aria-label="lab API tabs example"
            >
              <Tab label="General" value="1" className={classes.tab} />
              <Tab label="Scoring" value="2" className={classes.tab} />
              {/* <Tab label="Email" value="3" className={classes.tab} /> */}
              <Tab label="Profile" value="4" className={classes.tab} />
              <Tab label="Usage" value="5" className={classes.tab} />
              <Tab label="Webhooks" value="6" className={classes.tab} />
            </TabList>
          </Box>
          <TabPanel value="1" className={classes.tabPanel}>
            <SettingTitle title='Customize the experience for your users' />
            <General />
          </TabPanel>
          <TabPanel value="2" className={classes.tabPanel}>
            <SettingTitle title='Adjust weightage for Marking' />
            <Scoring />
          </TabPanel>
          {/* <TabPanel value="3" className={classes.tabPanel}>
            <SettingTitle title='Edit your Email copy' />
            <CustomizeEmail />
          </TabPanel> */}
          <TabPanel value="4" className={classes.tabPanel}>
            <SettingTitle title='Profile' />
            <Profile />
          </TabPanel>
          <TabPanel value="5" className={classes.tabPanel}>
            <Usage />
          </TabPanel>
          <TabPanel value="6" className={classes.tabPanel}>
            <SettingTitle title='Webhook URLs' />
            <WebhookSection />
          </TabPanel>
        </TabContext>
      </WhiteBox>
    </Box>
  );
}

export default Settings;